.navbar {
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-100);
  display: flex;
  justify-content: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  z-index: 100;
  /* drag 막기 */
  user-select: none;
}

.nav-center {
  width: 1220px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;
}
.nav-wrapper {
  padding: 0;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.nav-logo {
  font-size: var(--font-size-m);
  padding: 6px 10px 8px 10px;
  border-radius: 8px;
  text-decoration: none;
  color: var(--black);
  font-weight: var(--font-weight-bold);
}
.nav-logo:hover {
  background-color: black;
  color: white;
}
.nav-btn {
  font-size: var(--font-size-s);
  border-radius: var(--radius-s);
  padding: 6px 10px 8px 10px;
  margin-left: 24px;
  text-decoration: none;
  color: black;
}
.nav-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

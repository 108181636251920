.small-card {
  /* background-color: white; */
  border-radius: var(--radius-xxl);
  padding: 12px 12px 5px 12px;
  user-select: none;
  /* transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s; */
  transition: all ease 0.3s;
}

.small-card:hover {
  background-color: var(--white);
  box-shadow: var(--shadow-hover);
}

.small-card .thumbnail {
  background-color: white;
  height: 260px;
  border-radius: var(--radius-xl);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 17px;
}

.small-card .text-wrapper {
  margin: 14px 16px 14px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.small-card .text-wrapper h3 {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-bold);
  color: var(--black);
  padding: 0;
}

.small-card .text-wrapper .tag-wrapper {
  display: flex;
}

.small-card .text-wrapper .tag-wrapper .years-tag {
  margin-left: 4px;
  padding: 2px 6px 4px 6px;
  border-radius: var(--radius-xs);
  background-color: #d9d9d9;
  color: #bdbdbd;
  font-weight: bold;
  font-size: var(--font-size-xs);
}

.small-card .text-wrapper .tag-wrapper .UX-tag {
  margin-left: 4px;
  padding: 2px 6px 4px 6px;
  border-radius: 4px;
  background-color: #95c8e4;
  color: #599fc5;
  font-weight: bold;
  font-size: 14px;
}

.small-card .text-wrapper .tag-wrapper .BX-tag {
  margin-left: 4px;
  padding: 2px 6px 4px 6px;
  border-radius: 4px;
  background-color: #cec0e0;
  color: #a88ace;
  font-weight: bold;
  font-size: 14px;
}

.small-card .text-wrapper .tag-wrapper .motion-tag {
  margin-left: 4px;
  padding: 2px 6px 4px 6px;
  border-radius: 4px;
  background-color: #92aca6;
  color: #6f8580;
  font-weight: bold;
  font-size: 14px;
}
.thumbnail_01 {
  background-image: url("../../imgs/thumbnail_01.png");
}
.thumbnail_01_gif {
  animation: fade-in 0.5s;
  background-image: url("../../imgs/thumbnail_01.gif") !important;
}
.thumbnail_02 {
  background-image: url("../../imgs/thumbnail_02.png");
}
.thumbnail_02_gif {
  animation: fade-in 0.5s;
  background-image: url("../../imgs/thumbnail_02.gif") !important;
}
.thumbnail_03 {
  background-image: url("../../imgs/thumbnail_03.png");
}
.thumbnail_03_gif {
  animation: fade-in 0.5s;
  background-image: url("../../imgs/thumbnail_03.gif") !important;
}
.thumbnail_04 {
  background-image: url("../../imgs/thumbnail_04.png");
}
.thumbnail_04_gif {
  animation: fade-in 0.5s;
  background-image: url("../../imgs/thumbnail_04.gif") !important;
}
.thumbnail_05 {
  background-image: url("../../imgs/thumbnail_05.png");
}
.thumbnail_05_gif {
  animation: fade-in 0.5s;
  background-image: url("../../imgs/thumbnail_05.gif") !important;
}
.thumbnail_06 {
  background-image: url("../../imgs/thumbnail_06.png");
}
.thumbnail_06_gif {
  animation: fade-in 0.5s;
  background-image: url("../../imgs/thumbnail_06.gif") !important;
}
.thumbnail_07 {
  background-image: url("../../imgs/thumbnail_07.png");
}
.thumbnail_07_gif {
  animation: fade-in 0.5s;
  background-image: url("../../imgs/thumbnail_07.gif") !important;
}
.thumbnail_08 {
  background-image: url("../../imgs/thumbnail_08.png");
}
.thumbnail_08_gif {
  animation: fade-in 0.5s;
  background-image: url("../../imgs/thumbnail_08.gif") !important;
  background-size: 110% !important;
  background-position: center;
}

.thumbnail_09 {
  background-image: url("../../imgs/thumbnail_09.png");
}

.thumbnail_09_gif {
  background-image: url("../../imgs/thumbnail_09.gif") !important;
}

.thumbnail_10 {
  background-image: url("../../imgs/thumbnail_10.png");
}

.thumbnail_10_gif {
  background-image: url("../../imgs/thumbnail_10.gif") !important;
}
.thumbnail_11 {
  background-image: url("../../imgs/thumbnail_11.png");
}

.thumbnail_11_gif {
  background-image: url("../../imgs/thumbnail_11.gif");
}

.behance-badge {
  background-color: rgba(56, 56, 56, 0.2);
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  backdrop-filter: blur(30px);
  padding: 18px 20px;
  border-radius: 12px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  /* animation */
  animation: badge-in 0.5s;
}

/* animation */
@keyframes badge-in {
  0% {
    opacity: 0;
    transform: translateY(-15%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

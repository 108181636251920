:root {
  /* color */
  --white: #ffffff;
  --black: #000000;
  --gray-100: #f5f5f5;
  --gray-300: #f3f3f3;
  --gray-400: #bdbdbd;
  --background-color: #f9f9f9;

  /* font-size */
  --font-size-xxl: 28px;
  --font-size-xl: 22px;
  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 14px;

  /* font-weight */
  --font-weight-heavy: 900;
  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-weight-regular: 400;

  /* grid */
  --pc-maxwidth: 1224px;

  /* radius */
  --radius-xxl: 32px;
  --radius-xl: 24px;
  --radius-l: 18px;
  --radius-s: 8px;
  --radius-xs: 4px;

  /* spacing */
  --spacing-xl: 20px;
  --spacing-l: 20px;
  --spacing-m: 20px;
  --spacing-s: 20px;
  --spacing-xs: 20px;

  /* shadow */
  --shadow-hover: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --shadow: rgba(0, 0, 0, 0.02) 0px 0px 12px;
}

a {
  color: black;
  text-decoration: none;
  outline: none;
}

a:hover,
a:active {
  text-decoration: none;
}

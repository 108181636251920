.start_transition {
  opacity: 0;
}
.end_transition {
  animation: animation_fade-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.end_transition_row1 {
  animation: animation_fade-in 0.4s 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.end_transition_row2 {
  animation: animation_fade-in 0.4s 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.end_transition_row3 {
  animation: animation_fade-in 0.4s 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

@keyframes animation_fade-in {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animation_hover {
  0% {
    transform: translateY(0);
  }
  100% {
    background-color: var(--white);
    box-shadow: var(--shadow-hover);
    transform: translateY(-20px);
  }
}

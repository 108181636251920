.signal-img_title {
  background-size: 55%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: black;
  background-image: url(../../imgs/siganl/title.gif);
}

.signal-img_01 {
  background-image: url(../../imgs/siganl/01.png);
  background-position: center;
  background-size: contain;
  background-color: #eeeeee;
  background-repeat: no-repeat;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
  /* 개별정의 */
  height: 76vw;
  max-height: 550px;
}

.signal-img_02 {
  background-image: url(../../imgs/siganl/02.png);
  background-position: center;
  background-size: contain;
  background-color: #eeeeee;
  background-repeat: no-repeat;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
  /* 개별정의 */
  height: 76vw;
  max-height: 550px;
}
.signal-img_03 {
  background-image: url(../../imgs/siganl/03.png);
  background-position: center;
  background-size: contain;
  background-color: #faf9f9;
  background-repeat: no-repeat;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
  /* 개별정의 */
  height: 76vw;
  max-height: 550px;
}
.signal-img_04 {
  background-image: url(../../imgs/siganl/04.png);
  background-position: center;
  background-size: contain;
  background-color: #eeeeee;
  background-repeat: no-repeat;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
  /* 개별정의 */
  height: 76vw;
  max-height: 550px;
}
.signal-img_05 {
  background-image: url(../../imgs/siganl/05.png);
  background-position: center;
  background-size: contain;
  background-color: #4f5058;
  background-repeat: no-repeat;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
  /* 개별정의 */
  height: 76vw;
  max-height: 550px;
}
.signal-img_06 {
  background-image: url(../../imgs/siganl/06.png);
  background-position: center;
  background-size: contain;
  background-color: #eeeeee;
  background-repeat: no-repeat;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
  /* 개별정의 */
  height: 76vw;
  max-height: 550px;
}
.signal-img_07 {
  background-image: url(../../imgs/siganl/07.png);
  background-position: center;
  background-size: contain;
  background-color: #eeeeee;
  background-repeat: no-repeat;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
  /* 개별정의 */
  height: 76vw;
  max-height: 550px;
}

.signal-img_08 {
  background-image: url(../../imgs/siganl/08.png);
  background-position: center;
  background-size: contain;
  background-color: #eeeeee;
  background-repeat: no-repeat;
  /* border: 1px solid rgba(0, 0, 0, 0.05); */
  /* 개별정의 */
  height: 76vw;
  max-height: 550px;
}
